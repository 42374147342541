import { api, currentCreche } from '../api'

const staffRepository = {
    index (query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/staff`, query)
    },

    show (staffUuid) {
        return api().$get(`/${currentCreche().uuid}/staff/${staffUuid}`)
    },

    create (payload) {
        return api().$post(`/${currentCreche().uuid}/staff`, payload)
    },

    update (staffUuid, payload) {
        return api().$put(`/${currentCreche().uuid}/staff/${staffUuid}`, payload)
    },

    uploadAvatar (staffUuid, payload) {
        return api().$post(`/${currentCreche().uuid}/staff/${staffUuid}/avatar`, payload)
    },

    delete (staffUuid) {
        return api().$delete(`/${currentCreche().uuid}/staff/${staffUuid}`)
    },

    removeUser (staffUuid) {
        return api().$post(`/${currentCreche().uuid}/staff/${staffUuid}/remove-user`)
    },

    sendCreateAccountRequest (staffUuid) {
        return api().$post(`/${currentCreche().uuid}/staff/${staffUuid}/create-user`)
    },

    resendRequestAccessConfirm (staffUuid) {
        return api().$post(`/${currentCreche().uuid}/staff/${staffUuid}/resend-access-confirmation`)
    },

    quota (staffUuid, query = {}) {
        return api().$get(`/${currentCreche().uuid}/staff/${staffUuid}/quota`, query)
    }
}

export default staffRepository
